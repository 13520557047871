// Colors
$primary-color: #14AAB1;
$primary-overlay: #14a9b162;
$secondary-color: #F7B555;
$secondary-sub-color: #ffd1a3;
$secondary-overlay: #f5b06b75;
$thirdly-color: #3942A5;
$thirdly-sub-color: #676eb9;
$thirdly-overlay: #676eb957;
$fourthly-color: #f6f5f5;
$fifth-color: #1f7b94;
$sixth-color: #2b94b1;
$sidebar-background: #1e256b;
$light-color: #fff;
$dark-color: #5e5050;
$text-color: brown;
$darkred-color: #aa1c1c;
$green-color: #1da11d;

// Font
$primary-font: Cairo, 'sans-serif', Tahoma Arial;