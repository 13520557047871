@import "_variables";

/* === Start Global === */
* {
    box-sizing: border-box;
    scrollbar-width: thin;
    scrollbar-color: $secondary-sub-color $light-color;
}

*::-webkit-scrollbar {
    width: 12px; /* width of the entire scrollbar */
}

*::-webkit-scrollbar-track {
    background: $light-color; /* color of the tracking area */
    // border-left: 1px solid $primary;
}

*::-webkit-scrollbar-thumb {
    background-color: $secondary-sub-color; /* color of the scroll thumb */
}

::-moz-selection {
    /* Code for Firefox */
    color: var(--light);
    background: $primary-color;
}

::selection {
    color: var(--light);
    background: $primary-color;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: $primary-font;
    background: #fffdfa;
    position: relative;
}

section {
    padding: 5rem 0;
    
    @media (max-width: 991px) {
        padding-bottom: 3rem;
    }
}

div.section {
    position: relative;

    .section-icon {
        position: absolute;
        top: -11rem;
        left: 50%;
        transform: translateX(-50%);

        img {
            width: 70px;
            opacity: 0.8;
        }
    }
}

p {
    padding-bottom: 0;
}

.show-on-mobile {
    @media (min-width: 992px) {
        display: none;
    }
}


// Start Toastr
.toast {
    top: 1rem;
}

.toast-warning {
    background: $primary-color;
}

.toast-error {
    background: $darkred-color;
}

.toast-info {
    background: $primary-color;
}

.toast-success {
    background: $green-color;
}
// End Toastr


/* Section Title & Desc */
%section-title {
    position: relative;
    text-align: center;
    font-weight: bold;
    font-size: 40px;

    @media (max-width: 991px) {
        font-size: 30px;
    }
}

.section-title {
    @extend %section-title;
    color: $thirdly-sub-color;
}

.white-section-title {
    @extend %section-title;
    color: $light-color;
}

%section-desc {
    width: 50%;
    margin: auto;
    font-size: 24px;
    
    @media (max-width: 991px) {
        width: 90%;
    }
}

.section-desc {
    @extend %section-desc;
}

.white-section-desc {
    @extend %section-desc;
    color: $light-color;
}
/* /Section Title */

/* Navbar */
.navbar {
    background: $secondary-color;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    height: 90px;
    align-items: center;
    box-shadow: 0 0 7px #00000442;

    @media (max-width: 991px) {
        height: 70px;
    }

    // Nav Toggler
    .navbar-toggler {
        border: none;
        outline: 0;
        direction: rtl;

        .close-nav-line {
            background: #fff;
            height: 4px;
            margin: 0.3rem 0;
            border-radius: 5px;
        }

        .line-one {
            width: 35px;
        }

        .line-two {
            width: 25px;
        }

        .line-three {
            width: 15px;
        }
    }

    // Logo
    .navbar-brand {
        width: 15%;
        display: block;
        margin-left: 3rem;

        @media (max-width: 991px) {
            margin-right: 0;
            margin-left: 0;
            width: auto;
        }

        img {
            width: 180px;
            transition: 0.2s all;
            object-fit: cover;

            @media (max-width: 1199px) {
                width: 150px;
            }

            @media (max-width: 991px) {
                width: 120px;
            }
        }
    }

    .navbar-collapse {
        justify-content: center;

        .mobile-navbar-brand {
            display: block;
            text-align: left;

            img {
                width: 180px;
                max-width: 100%;
                margin-left: -1rem;
            }
        }

        .nav-menus {
            width: 70%;

            @media (max-width: 991px) {
                width: 100%;
                margin: 1rem 0;
                order: 2;

                li {
                    margin: 1rem 0;
                }
            }

            .navbar-nav {
                justify-content: center;
                column-gap: 3rem;
                grid-column-gap: 3rem;
                padding-right: 0;

                .nav-link-item {
                    position: relative;
                    color: $light-color;
                    text-shadow: 1px 1px #0000004f;
                    text-decoration: none;
                    padding-bottom: 0.3rem;
                    font-size: 20px;
                    padding: 5px 15px;
                    border-radius: 5px;
                    overflow: hidden;
                    backface-visibility: hidden;

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 100%;
                        height: 0%;
                        border-radius: 5px;
                        transition: .3s all;
                        background: $primary-color;
                        z-index: -1;
                        backface-visibility: hidden;
                        opacity: 0;
                    }

                    &:hover {
                        &::before {
                            height: 100%;
                            opacity: 1;
                        }
                    }

                    @media (max-width: 1600px) {
                        font-size: 16px;
                    }

                    @media (max-width: 991px) {
                        color: $light-color;
                    }
                }

                @media (max-width: 1199px) {
                    column-gap: 1rem;
                    grid-column-gap: 1rem;
                }

                @media (max-width: 991px) {
                    flex-direction: column;
                }
            }
        }

        .lang-switcher-container {
            width: 10%;

            @media (max-width: 991px) {
                width: 100%;
                order: 1;
                margin: 3rem auto 1rem auto;
            }

            .lang-switcher {
                display: grid;
                justify-items: start;
                grid-template-columns: 30px 30px;
                column-gap: 0.5rem;
                grid-column-gap: 0.5rem;

                @media (max-width: 991px) {
                    padding-left: 15px;
                }

                a {
                    text-decoration: none;
                }

                .lang-btn {
                    border: 1px solid $light-color;
                    border-radius: 2px;
                    color: $light-color;
                    font-size: 14px;
                    padding: 0.2rem 0.3rem;
                    cursor: pointer;
                    box-shadow: 0 0 6px #0000002e;
                    transition: 0.2s all;

                    &:hover {
                        color: $light-color;
                        background: $primary-color;
                        border-color: $primary-color;
                    }
                }
            }
        }

        .social-media {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            justify-items: center;
            width: 10%;
            grid-row-gap: 0.5rem;
            row-gap: 0.5rem;

            @media (max-width: 991px) {
                margin-top: 2rem;
                width: 90%;
                justify-items: start;
                grid-row-gap: 2rem;
                row-gap: 2rem;
                order: 3;
                padding-left: 15px;
            }

            .social-media-box {
                a {
                    display: block;

                    i {
                        color: $light-color;
                        font-size: 18px;
                        transition: 0.2s all;
                    }

                    &:hover {
                        i {
                            color: $primary-color;
                        }
                    }

                    @media (max-width: 991px) {
                        i {
                            color: $light-color;
                        }
                    }
                }
            }
        }
    }
}
/* /Navbar */

/* Sticky Navbar */
@media (min-width: 992px) {
    .sticky-navbar {
        height: 58px;

        .navbar-brand {
            img {
                width: 120px;
            }
        }

        .navbar-collapse {
            .nav-menus {
                .navbar-nav {
                    .nav-link-item {
                        font-size: 16px;
                    }
                }
            }

            .social-media {
                grid-row-gap: 0;
                row-gap: 0;
                .social-media-box {
                    a {
                        i {
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }
}
/* /Sticky Navbar */

/* Mobile Navbar */
@media (max-width: 991px) {
    .navbar-collapse {
        display: flex;
        flex-direction: column;
        justify-content: flex-start !important;
        align-items: flex-start;
        height: 100%;
        padding: 1rem 2rem;
        width: 80%;
        opacity: 0;
        position: fixed;
        left: -100%;
        top: 0;
        background: $thirdly-sub-color;
        transition: 0.3s all;

        .mobile-nav-logo {
            width: 100%;
        }

        .close-navbar-container {
            position: absolute;
            right: 1rem;
            top: 1rem;

            .close-navbar {
                cursor: pointer;
                color: $fourthly-color;
                font-size: 20px;
            }
        }
    }

    .navbar-collapse.show {
        left: 0;
        opacity: 1;
    }
}
/* /Mobile Navbar */

/* Section Title Decor */
.decor-container {
    margin: 1rem 0 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.2rem;

    > div {
        height: 3px;
        border-radius: 20px;
        opacity: 0.8;
    }

    .line-one {
        width: 120px;
        background: $secondary-color;
    }

    .line-two {
        width: 30px;
        background: $primary-color;
    }
}

.white-decor-container {
    margin: 1rem 0 3rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.2rem;

    > div {
        height: 3px;
        border-radius: 20px;
        opacity: 0.8;
    }

    .line-one {
        width: 120px;
        background: $light-color;
    }

    .line-two {
        width: 30px;
        background: $light-color;
    }
}
/* /Section Title Decor */
/* === End Global === */

/* === Start Carousel === */
.home-carousel-section {
    position: relative;
    margin-top: 90px;

    @media (max-width: 991px) {
        margin-top: 70px;
    }
    .home-carousel {
        .item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 700px;

            h2 {
                font-size: 40px;
                width: 60%;
                margin: auto;
                text-align: center;
                position: relative;
                z-index: 3;
                color: $fourthly-color;
                font-weight: bold;
                visibility: hidden;
                text-shadow: 1px 3px 3px var(--dark);

                @media (max-width: 991px) {
                    width: 100%;
                    font-size: 25px;
                    padding: 0.5rem;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                background: #292d528f;
                z-index: 2;
            }

            img {
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                z-index: 1;
            }

            @media (min-width: 1200px) and (max-width: 1600px) {
                height: 600px;
            }

            @media (min-width: 992px) and (max-width: 1199px) {
                height: 500px;
            }

            @media (max-width: 991px) {
                height: 400px;
            }
        }
    }

    .home-carousel-navs {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 100%;

        @media (max-width: 991px) {
            align-items: stretch;
            top: unset;
            bottom: 35px;
        }

        .owl-prev,
        .owl-next {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem;
            padding: 5px;
            width: 35px;
            height: 35px;
            outline: none;
            border: none;
            background: none;
            color: $secondary-color;
            font-size: 34px;

            @media (max-width: 991px) {
                background: $secondary-color;
                color: $light-color;
                margin: 0;
                font-size: 24px;
                padding: 10px;
                transition: 0.2s all;

                &:hover {
                    background: $primary-color;
                }
            }
        }

        .owl-prev {
            position: absolute;
            left: 0;
        }

        .owl-next {
            position: absolute;
            right: 0;
        }
    }
}
/* === End Carousel === */

/* === Start About Us === */
.about-us-content {
    display: flex;
    justify-content: space-between;

    .about-us-photo {
        height: 415px;
        width: 45%;

        .photo-holder {
            position: relative;
            box-shadow: 0 2px 6px #00000052;
            border-radius: 5px;
            overflow: hidden;
            height: 100%;

            img {
                height: 100%;
                object-fit: cover;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: $thirdly-overlay;
            }
        }
    }

    .about-us-text {
        width: 45%;

        p {
            text-align: justify;
            font-size: 20px;
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;

        .about-us-photo {
            width: 100%;
        }

        .about-us-text {
            width: 100%;
            margin-top: 0.5rem;
            padding: 0.5rem;
        }
    }
}
/* === End About Us === */

/* === Start Services === */
.our-service-section {
    position: relative;
    background: url('../../dist/img/orange-background-2.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;

    @media (max-width: 991px) {
        background-attachment: unset;
        transform: unset;
        -webkit-transform: unset;
    }

    &::before {
        content: '';
        position: absolute;
        opacity: .8;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: $secondary-color;
    }

    .services-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 6rem;
        gap: 6rem;
        // justify-items: center;
        text-align: center;

        @media (max-width: 991px) {
            grid-template-columns: 1fr;
            grid-gap: 2rem;
            gap: 2rem;
        }

        .service-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            color: $dark-color;
            border-radius: 10px;
            cursor: pointer;
            transition: 0.5s all;
            box-shadow: 1px 1px 6px rgba(2, 2, 2, 0.192);
            background: $light-color;
            

            .service-icon {
                img {
                    width: 75px;
                    height: 76px;
                    padding: 0.6rem;
                }
            }

            .service-details {
                margin-bottom: auto;
            }

            &:hover {
                background: $sixth-color;
                color: $light-color;
            }
        }
    }
}
/* === End Services === */

/* === Start Partners === */
.partners-carousel-container {
    .partners-carousel {
        padding: 1rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        grid-gap: 3rem;
        gap: 3rem;
        flex-wrap: wrap;

        img {
            width: 200px;
            object-fit: cover;
            // filter: grayscale(100%);
            transition: 0.5s all;

            // &:hover {
            //     filter: none;
            // }

            @media (max-width: 991px) {
                filter: none;
                width: 150px;
            }
        }
    }

    .partners-carousel-navs {
        display: flex;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
        width: 100%;

        .owl-prev,
        .owl-next {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 1rem;
            padding: 5px;
            width: 35px;
            height: 35px;
            outline: none;
            border: none;
            background: none;
            color: $primary-color;
            font-size: 34px;
            position: absolute;
        }

        .owl-prev {
            left: -3rem;
        }

        .owl-next {
            right: -3rem;
        }
    }

    .partners-carousel-dots {
        display: flex;
        justify-content: center;
        column-gap: 0.3rem;
        grid-column-gap: 0.3rem;

        .owl-dot {
            outline: none;
            border: none;
            background: $primary-overlay;
            height: 5px;
            border-radius: 10px;
            width: 10px;
            transition: 0.3s all;

            &.active {
                background: $primary-color;
                width: 30px;
            }
        }
    }
}
/* === End Partners === */

/* === Start Contact Us === */
.contact-section {
    position: relative;
    background: url('../../dist/img/orange-background-5.webp') no-repeat center;
    background-size: cover;
    background-attachment: fixed;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: $secondary-overlay;
        opacity: 1;
    }

    .contact-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 991px) {
            flex-direction: column;
        }

        .contact-info {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 2rem;
            row-gap: 2rem;
            align-items: center;
            width: 45%;

            @media (max-width: 991px) {
                width: 90%;
                grid-row-gap: 1.5rem;
                row-gap: 1.5rem;
            }

            hr {
                margin: 0;
                width: 55%;

                @media (max-width: 991px) {
                    width: 90%;
                }
            }

            .contact-item {
                display: flex;
                align-items: center;
                font-size: 20px;

                @media (max-width: 991px) {
                    font-size: 16px;
                }

                .contact-item-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 30px;

                    i {
                        color: $secondary-sub-color;
                        text-align: center;
                        font-size: 30px;

                        @media (max-width: 991px) {
                            font-size: 20px;
                        }
                    }
                }

                .contact-item-content {
                    min-width: 200px;

                    span {
                        color: $light-color;

                        a {
                            text-decoration: none;
                            color: $light-color;
                        }
                    }
                }
            }
        }

        .form-container {
            width: 45%;

            @media (max-width: 991px) {
                width: 90%;
                margin-top: 2rem;
            }

            ::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                color: gray;
                opacity: 1; /* Firefox */
            }

            :-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                color: gray;
            }

            ::-ms-input-placeholder {
                /* Microsoft Edge */
                color: gray;
            }

            input,
            textarea {
                outline: 0;
                border: 1px solid #ccc;
                border-radius: 5px;
                color: gray;
                transition: 0.2s all;

                &:hover {
                    border-color: $secondary-color;
                }

                &:focus {
                    border-color: $secondary-color;
                }
            }

            label {
                width: 100%;
                margin-bottom: 0.2rem;
                color: $secondary-color;
                font-size: 20px;
            }

            .input-holder {
                position: relative;
                margin-bottom: 1rem;

                .input-icon {
                    position: absolute;
                    left: 0.8rem;
                    top: 50%;
                    transform: translateY(-50%);
                    color: $secondary-color;
                }

                input {
                    width: 100%;
                    padding: 0.5rem 2.5rem;
                }
            }

            .textarea-holder {
                position: relative;

                .textarea-icon {
                    position: absolute;
                    left: 0.8rem;
                    top: 0.5rem;

                    i {
                        color: $secondary-color;
                    }
                }

                textarea {
                    width: 100%;
                    padding: 0.5rem 2.5rem;
                    resize: none;
                }
            }

            .submit-btn {
                position: relative;
                width: 100%;
                background: $primary-color;
                padding: 0.5rem;
                border: none;
                border-radius: 5px;
                outline: 0;
                transition: 0.2s all;
                overflow: hidden;

                &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: 0%;
                    opacity: 0;
                    transform: translateX(-50%);
                    background: $light-color;
                    transition: 0.4s all;
                }

                span {
                    position: relative;
                    color: #fff;
                    font-size: 18px;
                    transition: 0.4s all;
                }

                &:hover {
                    span {
                        color: $secondary-color;
                    }

                    &::before {
                        width: 101%;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .map-container {
        width: 100%;
        height: 500px;
        margin: 2rem auto;

        padding: 0;

        @media (max-width: 991px) {
            height: 400px;
        }

        #map {
            height: 100%;
            width: 100%;
            border-radius: 5px;
            box-shadow: 0 2px 6px #00000026;

            @media (max-width: 991px) {
                border-radius: 0;
            }
        }
    }
}
/* === End Contact Us === */

/* === Start Footer === */
footer {
    position: relative;

    .copyrights {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 10px;
        // background: $secondary-color;

        span {
            color: $fourthly-color;
        }
    }
}
/* === End Footer === */

/* === Start Back To Top === */
.back-to-top {
    position: fixed;
    right: 5%;
    bottom: 1.5rem;
    z-index: 999;
    width: 40px;
    height: 40px;
    background: $primary-color;
    color: $light-color;
    border-radius: 10px;
    box-shadow: 0 0 6px #0000003b;
    cursor: pointer;
    display: none;
    transition: .2s opacity;

    @media (max-width: 991px) {
        bottom: 5rem;
        right: 1rem;
    }

    a {
        text-decoration: none;
        color: $light-color;
    }

    i {
        font-size: 34px;
    }

    &.activeScroll {
        display: flex;
    }
}
/* === End Back To Top === */
